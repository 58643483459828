import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import userDetail from "Stores/userDetail";
import contactStoreData from "Stores/contactData";
import TabNavigation from "Pages/Dashboard/settings/Crm/common/TabNavigation";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { CONTACT_TIMELINE_TAB_NAVIGATION } from "Static/constant";
import IconType from "Pages/Dashboard/contacts/ContactTimeline/IconType";
import ActivityFeedCard from "Pages/Dashboard/contacts/ContactTimeline/ActivityFeedCard";
import ContactCard from "Components/ContactCard/ContactCard";
import { ContactTimelineStore } from "Pages/Dashboard/contacts/ContactTimeline/ContactTimelineStore";
import FilterBy from "./FilterBy";
import DateUtils from "Utils/DateUtils";
import { Utils } from "Utils/utils";
import { emailPreviewContentPopupState } from "Components/PreviewPopup/EmailPreviewContentPopup";
import { CONTACT_PREVIEW_EVENT_TYPE } from "Model/model";
import InboxUtils from "Utils/InboxUtils";

const ContactTimeline = observer((props = {}) => {
  const {
    isTaskPage = false,
    contactIdFromTaskPage = "",
    timelineLoader = false,
    setTimelineLoader = () => {},
    triggerTimeline = false,
    setTriggerTimeline = () => {},
    accountId = ""
  } = props || {};

  let { contactId: cId = "" } = useParams();

  let contactId = isTaskPage ? contactIdFromTaskPage : cId;
  let currentUserEmail = userDetail?.userInfo?.email;

  const { userFeatureFlag = {} } = userDetail || {};

  const { inFreePlan = false, trialEnabled = false } = userFeatureFlag || {};
  let isFreeUser = inFreePlan && !trialEnabled;

  const [data, setData] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [loading, setLoading] = useState(timelineLoader);
  const [pageLoading, setPageLoading] = useState(false);

  const getPayload = (value = "") => {
    let payload = {
      limit: 20,
      pageNo: 1
    };
    setActiveFilter(value);
    if (value) {
      payload.group = value;
    }
    return payload;
  };

  const handlePagination = () => {
    if (!pageLoading) {
      if (!isFreeUser) {
        setPageLoading(true);
        if (!loading && +data?.currentPage < +data?.totalPages) {
          let payload = {
            limit: 20,
            pageNo: data?.currentPage + 1
          };
          if (activeFilter) {
            payload.group = activeFilter;
          }
          ContactTimelineStore.getContactActivity(
            contactId,
            payload,
            accountId
          );
        }
      } else {
        upgradePopupState.setPopupValues({ type: "contactActivityPagination" });
        upgradePopupState.setPopup(true);
      }
    }
  };

  const handleAction = (value = "") => {
    if (!pageLoading && !loading) {
      if (!isFreeUser) {
        setLoading(true);
        let payload = getPayload(value);
        ContactTimelineStore.getContactActivity(contactId, payload, accountId);
      } else {
        upgradePopupState.setPopupValues({
          type: "contactActivityFilter"
        });
        upgradePopupState.setPopup(true);
      }
    }
  };

  const handleLinkedInReply = obj => {
    ContactTimelineStore.getLinkedInReplyForActivity(obj?.contactId);
  };

  const handlePreview = (event = "", allowPreview = false, obj = {}) => {
    Utils.preventDefaultFn(event);
    if (allowPreview) {
      let tempObj = { ...obj, pageType: "timelineReply" };
      if (obj?.eventType?.toLowerCase() === "li_message_sent") {
        handleLinkedInReply(obj);
      } else {
        if (obj?.replySource?.toLowerCase() === "linkedin") {
          handleLinkedInReply(obj);
        } else {
          if (
            [
              "sent",
              "opened",
              "clicked",
              "replied",
              "received_email"
            ]?.includes(obj?.eventType?.toLowerCase())
          ) {
            if (obj?.eventType?.toLowerCase() === "replied") {
              const { replyContent = "", emailData = {} } = obj || {};
              const {
                from = {},
                to = {},
                cc = {},
                repliedCcAddress = [],
                repliedToAddress = [],
                repliedFromAddress = []
              } = emailData || {};
              let tempEmailData = {
                ...emailData,
                from: repliedFromAddress ? { email: repliedFromAddress } : from,
                to:
                  Array.isArray(repliedToAddress) && repliedToAddress.length > 0
                    ? InboxUtils.getEmailAddressList(repliedToAddress)
                    : to,
                cc:
                  Array.isArray(repliedCcAddress) && repliedCcAddress.length > 0
                    ? InboxUtils.getEmailAddressList(repliedCcAddress)
                    : cc,
                content: replyContent
              };
              tempObj = {
                ...tempObj,
                emailData: tempEmailData
              };
            } else {
              const { emailData = {} } = obj || {};
              tempObj = {
                ...tempObj,
                emailData
              };
              emailPreviewContentPopupState.setIsFromTimeline(true);
            }
            emailPreviewContentPopupState.setShowReplyContent(
              obj?.eventType?.toLowerCase() === "replied"
            );
            emailPreviewContentPopupState.setPopupValues({
              ...tempObj,
              currentUserEmail,
              isEnableReplyFrwd: obj?.eventType?.toLowerCase() === "replied",
              showViewReplyBtn: false
            });
            emailPreviewContentPopupState.setShowPopup(true);
          }
        }
      }
    }
  };

  const getContactTimelineData = (accountId = "") => {
    setLoading(true);
    let payload = {
      limit: 20,
      pageNo: 1
    };
    if (activeFilter) {
      payload.group = activeFilter;
    }
    ContactTimelineStore.getContactActivity(contactId, payload, accountId);
  };

  const getContactActivityTimelineData = (accountId = "") => {
    if (contactId || triggerTimeline || accountId) {
      if (triggerTimeline) {
        setTriggerTimeline(false);
        setTimelineLoader(false);
      }
      getContactTimelineData(accountId);
    }
  };

  useEffect(() => {
    getContactActivityTimelineData(accountId);
  }, [contactId, triggerTimeline, accountId]);

  useEffect(() => {
    if (ContactTimelineStore?.contactActivityData?.statusCode) {
      setData(ContactTimelineStore?.contactActivityData);
      setPageLoading(false);
      setLoading(false);
      setTimelineLoader(false);
      setTriggerTimeline(false);
    }
  }, [ContactTimelineStore.contactActivityData]);

  useEffect(() => {
    if (timelineLoader) {
      setLoading(true);
    }
  }, [timelineLoader]);

  useEffect(() => {
    return () => {
      ContactTimelineStore.resetValue();
      contactStoreData.setBreadCrumbValue("");
    };
  }, []);

  useEffect(() => {
    if (ContactTimelineStore.reloadTaskTimelineActivity) {
      getContactActivityTimelineData();
      ContactTimelineStore.setReloadTaskTimelineActivity(false);
    }
  }, [ContactTimelineStore.reloadTaskTimelineActivity]);

  return (
    <div className="ctTlSection">
      <div className="ctTlBody">
        {!isTaskPage && (
          <TabNavigation
            tabList={CONTACT_TIMELINE_TAB_NAVIGATION}
            activeTab={"activity"}
          />
        )}
        <div className="ctTlContentBody">
          {isTaskPage && <div className="title">Activity</div>}
          {(data === "" ||
            data?.totalElements > 0 ||
            (data?.totalElements < 1 && activeFilter !== "")) && (
            <div className="ctTlFilter">
              <div />
              <div className="wrapCtTlFilter">
                {!loading && data !== "" ? (
                  <FilterBy
                    actionCbk={handleAction}
                    activeFilter={activeFilter}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          <div
            className={`ctTlHistoryList ${
              !loading && data?.totalElements < 1 && activeFilter === ""
                ? "noDataFound"
                : ""
            }`}
          >
            {!loading ? (
              data?.timeline?.length > 0 ? (
                data?.timeline?.map((item, index) => {
                  const { result = [], month = "" } = item || {};
                  return (
                    <div
                      className="monthlyActivityFeed"
                      key={`activityFeedCard${index}`}
                    >
                      <div className="ctTlMonth">
                        <span className="dot" />
                        <span className="month">
                          {month}
                          {`, ${DateUtils.getMonthFullYear(
                            result?.[0]?.createTs
                          )}`}
                        </span>
                      </div>
                      {result.map((list, idx) => {
                        const {
                          group = "",
                          eventType = "",
                          replySource = "",
                          taskType = ""
                        } = list || {};
                        let allowPreview =
                          CONTACT_PREVIEW_EVENT_TYPE.includes(eventType) ||
                          [
                            "linkedin",
                            "customtask",
                            "manualemail",
                            "phone"
                          ].includes(taskType?.toLowerCase());
                        return (
                          <div
                            className={`ctTlCard ${
                              allowPreview ? "showPreview" : ""
                            }`}
                            key={`data${idx}${index + 1}`}
                            onClick={event =>
                              handlePreview(event, allowPreview, list)
                            }
                          >
                            <IconType
                              eventType={
                                eventType?.toLowerCase() === "replied" &&
                                replySource?.toLowerCase() === "linkedin"
                                  ? "li_replied"
                                  : eventType?.toLowerCase()
                              }
                              group={group?.toLowerCase()}
                              taskType={
                                ["linkedin", "customtask", "phone"].includes(
                                  taskType?.toLowerCase()
                                )
                                  ? taskType
                                  : false
                              }
                            />
                            <ActivityFeedCard
                              {...list}
                              currentUserEmail={currentUserEmail}
                              isTaskPage={isTaskPage}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <EmptyContactActivityFeed accountId={accountId} />
              )
            ) : (
              <DataCardLoader isTaskPage={isTaskPage} />
            )}
            {!loading &&
              data?.totalElements > 0 &&
              +data?.currentPage !== +data?.totalPages && (
                <div className="paginateActivity" onClick={handlePagination}>
                  {pageLoading ? "Loading..." : "Load more activity"}
                </div>
              )}
          </div>
        </div>
      </div>
      {!isTaskPage && (
        <div className="ctTlSidePanelCard">
          <ContactCard
            cardType="timeline"
            pageType="Contact Timeline Page"
            contact={{ id: contactId }}
            isContactSlider={false}
            isReloadCbk={() => getContactTimelineData()}
            isReload={true}
          />
        </div>
      )}
    </div>
  );
});

const DataCardLoader = (props = {}) => {
  const { isTaskPage = false } = props || {};

  return Array.from({ length: 5 }, (value, idx) => (
    <div className="monthlyActivityFeed" key={`${idx}`}>
      <div className="ctTlMonth">
        <span className="dot linear-background" />
        <span className="month linear-background" />
      </div>
      {Array.from({ length: 4 }, (value, index) => (
        <div
          className="ctTlCardLoader"
          key={`contactActivityFeedLoader${index + 1}`}
        >
          <div className="svgIconType linear-background" />
          <div className="activityFeedCard">
            <div className="contentCard">
              <p className="linear-background" />
              <div className="emailSubjectContent linear-background" />
              <div className="dFlex">
                <div className="clickLink linear-background" />
                <div className="sequenceDetails linear-background" />
              </div>
            </div>
            {!isTaskPage && <div className="calenderDate linear-background" />}
          </div>
        </div>
      ))}
    </div>
  ));
};

const EmptyContactActivityFeed = ({ accountId = "" }) => {
  return (
    <div className="emptyActivity">
      <span className="infoText">{`No ${
        accountId ? "account" : "contact"
      } activity found`}</span>
    </div>
  );
};

export default ContactTimeline;
export { ContactTimeline };
