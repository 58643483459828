import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { OutboxContext } from "../main";
import userDetail from "Stores/userDetail";
import { outboxStoreData } from "../outboxStore";
import { emailPreviewContentPopupState } from "Components/PreviewPopup/EmailPreviewContentPopup";
import Utils from "Utils/utils";
import DateUtils from "Utils/DateUtils";
import { OutboxUtils } from "Utils/OutboxUtils";
import InboxUtils from "Utils/InboxUtils";
import CheckBox from "Components/common/CheckBox";
import CustomTooltip from "Components/common/CustomTooltip";
import { EmailScheduleDateAndTime } from "../CommonEmailContents";
import { ReactComponent as ContactIcon } from "Assets/svg/outbox/name.svg";
import { ReactComponent as EmailSubjectIcon } from "Assets/svg/outbox/emailSubject.svg";
import { ReactComponent as ContactHoverIcon } from "Assets/svg/outbox/hover/nameHover.svg";
import { ReactComponent as EmailSubjectHoverIcon } from "Assets/svg/outbox/hover/emailSubjectHover.svg";
import { ReactComponent as LinkedinIcon } from "Assets/svg/linkedInOutlined.svg";
import { observer } from "mobx-react-lite";
import { linkedInReplyPopupState } from "Components/PreviewPopup/LinkedInReplyContentPopup";

const OutboxCard = observer((props = {}) => {
  const {
    inputValues = {},
    setInputValues = () => {},
    getReloadOutboxCardData = () => {}
  } = useContext(OutboxContext);
  const { activeFilter = {} } = inputValues || {};
  const { name = "" } = outboxStoreData?.activeFilter || {};
  const isScheduledorAllFilter = ["all", "scheduled"].includes(
    name?.toLowerCase()
  );

  const {
    contact = {},
    task = {},
    contactOwner = {},
    sequence = {},
    openContactCard = () => {},
    index = 0,
    isChecked = false
  } = props || {};

  const {
    id: contactId = "",
    firstName = "",
    lastName = "",
    email: emailAddress = ""
  } = contact?.[0] || {};

  let {
    id = "",
    touchPoint = {},
    status = "",
    taskDue = "",
    sentDate = "",
    replyDate = "",
    order = "",
    subject = "",
    content = "",
    shortContent = content,
    customTask = false,
    scheduleCode = "",
    userEdit = false,
    emailEditedDate = "",
    taskType = "",
    replyContent = "",
    sentCount = "",
    openCount = "",
    clickCount = "",
    replyCount = "",
    oneOffEmail = false,
    timelineEmail = false,
    readStatus = "READ"
  } = task || {};

  const emailInsights = {
    sentCount,
    openCount,
    clickCount,
    replyCount
  };

  if (touchPoint != null) {
    subject =
      status?.toLowerCase() === "replied"
        ? touchPoint?.repliedSubject
        : touchPoint?.subject;
    shortContent =
      status?.toLowerCase() === "replied"
        ? replyContent
        : touchPoint?.shortContent;
  }
  const {
    name: sequenceName = "",
    folderId = "",
    shareType = "",
    id: sequenceId = ""
  } = sequence || {};
  const {
    firstName: contactOwnerFirstName = "",
    lastName: contactOwnerLastName = "",
    email: contactOwnerEmail = ""
  } = contactOwner || {};

  const [showHoverCard, setShowHoverCard] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);

  const getHTMLContentAsText = (content = "") => {
    const elem = document.createElement("div");
    if (elem) {
      elem.innerHTML = content;
      return elem?.innerText;
    }
  };

  const handleOutboxCardClick = outboxId => {
    if (readStatus === "UNREAD" && status?.toLowerCase() === "replied") {
      outboxStoreData.markAsRead(id, getReloadOutboxCardData);
    }
    outboxStoreData.setActiveOutboxId(outboxId);
    if (taskType?.toLowerCase() === "linkedinauto") {
      if (touchPoint?.linkedInTaskType?.toLowerCase() === "li_send_message") {
        outboxStoreData.getLinkedInReplyForActivity(contactId);
      } else {
        return;
      }
    } else {
      if (status?.toLowerCase() === "replied") {
        const {
          fromAddress = "",
          toAddress = "",
          ccAddress = "",
          bccAddress = "",
          repliedFromAddress = "",
          repliedToAddress = [],
          repliedCcAddress = []
        } = touchPoint || {};
        emailPreviewContentPopupState.setShowReplyContent(true);
        emailPreviewContentPopupState.setPopupValues({
          eventType: "REPLIED",
          pageType: "inbox",
          showViewReplyBtn: false,
          emailData: {
            from: repliedFromAddress
              ? { email: repliedFromAddress }
              : { email: fromAddress },
            to:
              Array.isArray(repliedToAddress) && repliedToAddress.length > 0
                ? InboxUtils.getEmailAddressList(repliedToAddress)
                : InboxUtils.getEmailAddressList(toAddress),
            cc:
              Array.isArray(repliedCcAddress) && repliedCcAddress.length > 0
                ? InboxUtils.getEmailAddressList(repliedCcAddress)
                : InboxUtils.getEmailAddressList(ccAddress),
            bcc: InboxUtils.getEmailAddressList(bccAddress),
            subject,
            content: replyContent || content || shortContent || ""
          },
          taskId: id,
          createTs: replyDate || sentDate,
          taskNotes: touchPoint?.notes,
          sequenceId,
          replyContent,
          sequenceName,
          order,
          currentUserEmail: userDetail?.userInfo?.email,
          folderId,
          sequenceShareType: shareType,
          email: "",
          taskType,
          autoEmail: taskType?.toLowerCase() === "email",
          group: "EMAIL",
          emailInsights,
          hideViewReplyBack: true,
          oneOffEmail,
          timelineEmail,
          isEnableReplyFrwd: true,
          pageType: "outboxReply"
        });
        emailPreviewContentPopupState.setShowPopup(true);
      } else {
        outboxStoreData.setActiveDataInPopup(props);
        outboxStoreData.setOutboxCardPopup(true);
      }
    }
  };

  const handleCheckboxAction = (value = false, type = "") => {
    setInputValues({
      ...inputValues,
      outboxData: {
        ...inputValues.outboxData,
        result: inputValues?.outboxData?.result?.map(item =>
          item?.email?.id === type ? { ...item, isChecked: value } : item
        )
      }
    });
  };

  const emailStatus = OutboxUtils.getEmailStatus(status, scheduleCode);

  return (
    <>
      <div
        className={`outboxCardWrapper ${
          readStatus === "UNREAD" && status?.toLowerCase() === "replied"
            ? "inboxMailUnread"
            : "inboxMailRead"
        } ${isScheduledorAllFilter ? "" : "adjustWidth"} ${
          outboxStoreData.activeOutboxId === index ? "isActiveCard" : ""
        }`}
        onMouseEnter={() => setShowHoverCard(true)}
        onMouseLeave={() => setShowHoverCard(false)}
        onClick={() => handleOutboxCardClick(index)}
      >
        {!isScheduledorAllFilter && (
          <div className="checkboxBtn">
            <CheckBox
              id={id}
              key={id}
              checked={isChecked}
              cbk={handleCheckboxAction}
            />
          </div>
        )}
        <div className="recipientInfoSection">
          <div className="recipientInfo">
            {showHoverCard ? <ContactHoverIcon /> : <ContactIcon />}
            <div
              className="flexCenter"
              onClick={event => {
                Utils.preventDefaultFn(event);
                openContactCard(contact?.[0] || {});
              }}
            >
              {Utils.getName(firstName, lastName)}
              <div
                className="recipientEmail"
                title={emailAddress}
              >{`<${emailAddress}>`}</div>
            </div>
            {contact?.length > 1 && (
              <span className="recipientCount">+{contact?.length - 1}</span>
            )}
          </div>
        </div>
        <div
          className={`emailInfoWrapper ${
            taskType?.toLowerCase() === "linkedinauto" && !touchPoint?.text
              ? "noTemplateArea"
              : ""
          }`}
        >
          <div className="subjectIcon">
            {taskType?.toLowerCase() === "linkedinauto" ? (
              touchPoint?.text ? (
                <span className="liIcon">
                  <LinkedinIcon />
                </span>
              ) : (
                <></>
              )
            ) : showHoverCard ? (
              <EmailSubjectHoverIcon />
            ) : (
              <EmailSubjectIcon />
            )}

            {userEdit &&
              ["email"].includes(taskType?.toLowerCase()) &&
              emailEditedDate && (
                <div
                  className="editScheduleDate"
                  onMouseEnter={() => setShowToolTip(true)}
                  onMouseLeave={() => setShowToolTip(false)}
                >
                  <span className="orangeDot" />
                  {showToolTip && (
                    <CustomTooltip
                      text={`Updated on ${DateUtils.getDateAndMonth(
                        emailEditedDate,
                        false,
                        true
                      )}`}
                      className={`${index === 0 ? "firstCard" : ""}`}
                    />
                  )}
                </div>
              )}
          </div>
          <div className="emailInfoCont">
            {taskType?.toLowerCase() === "linkedinauto" ? (
              <span className="content" title={touchPoint?.text}>
                {touchPoint?.text || "--"}
              </span>
            ) : (
              <>
                <span className="subject">{subject}</span>
                {(shortContent || content) && (
                  <>
                    <span className="seperatorHyphen">-</span>
                    <span className="content">
                      {getHTMLContentAsText(shortContent || content)}
                    </span>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {(sequenceName && (
          <div
            className={`sequenceSection ${customTask ? "noStyling" : ""}`}
            title={sequenceName}
            onClick={e => {
              e.stopPropagation();
              props.history.push(OutboxUtils.getSequenceURL(sequence));
            }}
          >
            <div className="sequenceNamecont">{sequenceName}</div>
          </div>
        )) || <div />}
        {activeFilter?.id !== "ERROR" && (
          <EmailScheduleDateAndTime
            taskDue={
              status?.toLowerCase() === "sent"
                ? sentDate
                : status?.toLowerCase() === "replied"
                ? replyDate || sentDate
                : taskDue
            }
            emailStatus={emailStatus}
            showHoverCard={showHoverCard}
          />
        )}
        <div className="contactOwner">
          <span className="circleOwner">
            {Utils.getOwnerInitial({
              firstName: contactOwnerFirstName,
              lastName: contactOwnerLastName,
              email: contactOwnerEmail
            })}
          </span>
        </div>
      </div>
    </>
  );
});

export { OutboxCard };
export default withRouter(OutboxCard);
