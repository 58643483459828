import React, { useState, useEffect, useContext } from "react";
import { OutboxContext } from "./main";
import { observer } from "mobx-react";
import { outboxStoreData } from "./outboxStore";
import MutliSelectDropdown from "./Dropdown/MultiSelectDropdown";
import ConnectInboxDropdown from "./Dropdown/ConnectInboxDropdown";
import MultiSelectDateFilter from "./Dropdown/MultiSelectDateFilter";
import AddMultiInputEmail from "./Dropdown/AddMultiInputEmail";
import Utils from "Utils/utils";

export const OutboxFilterByOption = observer(
  ({ applyFilterCbk = () => {}, resetFilterCbk = () => {} }) => {
    const {
      sequenceLoader = false,
      data = {},
      setShowFilter = () => {},
      setData = () => {},
      setAllEmailsSelectedCheckbox = () => {},
      setAllEmailsSelectedInView = () => {}
    } = useContext(OutboxContext);

    const [activeDropdown, setActiveDropdown] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    // Action
    const clearAll = (actionType = "") => {
      if (
        outboxStoreData?.filterList?.length > 0 ||
        outboxStoreData?.tempFilterList?.length > 0
      ) {
        outboxStoreData.setActiveOutboxId("");
        outboxStoreData.setCurrentPage(1);
        let value = resetFilterCbk();
        if (value) {
          outboxStoreData?.filterList?.length > 0 &&
            applyFilterCbk(actionType || "clear");
          outboxStoreData.setTempFilterList([]);
          outboxStoreData.setFilterList([]);
        }
      }
      setAllEmailsSelectedCheckbox(false);
      setAllEmailsSelectedInView(false);
    };

    const cancelFilter = () => {
      clearAll("cancel");
      setShowFilter(false);
    };

    const applyFilter = () => {
      if (
        outboxStoreData?.filterList?.length > 0 ||
        outboxStoreData?.tempFilterList?.length > 0
      ) {
        outboxStoreData.setCurrentPage(1);
        outboxStoreData.setFilterList([...outboxStoreData.tempFilterList]);
        outboxStoreData.setFilterObject({ ...data });
        applyFilterCbk(true);
      }
    };

    const actionTypeFuncObject = {
      clear: clearAll,
      apply: applyFilter,
      cancel: cancelFilter
    };

    const submitAction = (type = "") => {
      actionTypeFuncObject[type]();
    };

    // Dropdown Action
    const setFiterUsed = (isValidList = false, key = "") => {
      if (key) {
        let filterList = [...outboxStoreData?.tempFilterList];
        if (isValidList && !filterList.includes(key)) {
          filterList.push(key);
        } else if (!isValidList && filterList?.length > 0) {
          filterList = filterList.filter(
            item => item?.toLowerCase() !== key?.toLowerCase()
          );
        }
        outboxStoreData.setTempFilterList(filterList);
      }
    };

    const getSelectedFilterKey = (key = "", value = []) => {
      if (key) {
        let arr = [];
        if (!["dates", "toAddress"].includes(key)) {
          arr = value.filter(item => item?.checked) || [];
        } else if (key === "toAddress") {
          arr = value;
        } else if (key === "dates") {
          arr =
            value.filter(
              item => item?.fieldName && item?.fromDate && item?.toDate
            ) || [];
        }
        setFiterUsed(arr?.length > 0, key);
      }
    };

    const getSelectedItems = (item = []) => {
      if (item?.length > 0) {
        return item.filter(item => item?.checked) || [];
      }
      return [];
    };

    const handleFilterObject = (key = "", value = []) => {
      if (key) {
        getSelectedFilterKey(key, value);
        let tempObj = { ...data, [key]: value };
        setData({ ...tempObj });
      }
    };

    const handleActiveFilter = (value = "") => {
      setActiveDropdown(value === activeDropdown ? "" : value);
    };

    const getListArr = () => {
      return data?.listIds?.filter(item => item?.id) || [];
    };

    // Dropdown List
    const dropdownList = [
      {
        label: "Owners",
        keyValue: "owners",
        placeholder: "Select",
        selected: getSelectedItems(data?.owners),
        data: data?.owners || [],
        setAction: handleFilterObject,
        activeFilter: activeDropdown,
        setActiveFilter: handleActiveFilter,
        type: "multiSelectApply",
        count: 2
      },
      {
        label: "From Address",
        keyValue: "fromAddress",
        placeholder: "Select",
        selected: getSelectedItems(data?.fromAddress),
        data: data?.fromAddress || [],
        setAction: handleFilterObject,
        activeFilter: activeDropdown,
        setActiveFilter: handleActiveFilter,
        type: "singleSelect"
      },
      {
        label: "To Address",
        keyValue: "toAddress",
        selected: data?.toAddress,
        data: data?.toAddress || [],
        placeholder: "Enter recipient address",
        setAction: handleFilterObject,
        activeFilter: activeDropdown,
        setActiveFilter: handleActiveFilter,
        type: "multiInputSelect",
        autoComplete: true,
        count: 3
      },
      {
        label: "Sequences",
        keyValue: "sequences",
        placeholder: "Select",
        selected: getSelectedItems(data?.sequences),
        data: data?.sequences || [],
        setAction: handleFilterObject,
        activeFilter: activeDropdown,
        setActiveFilter: handleActiveFilter,
        type: "multiSelectApply",
        count: 2,
        loader: sequenceLoader
      },
      {
        label: "Email Type",
        keyValue: "emailType",
        placeholder: "Select",
        selected: getSelectedItems(data?.emailType),
        data: data?.emailType || [],
        setAction: handleFilterObject,
        activeFilter: activeDropdown,
        setActiveFilter: handleActiveFilter,
        type: "multiSelectApply",
        count: 4
      },
      {
        label: "Date",
        keyValue: "dates",
        data: data?.dates || [],
        activeFilter: activeDropdown,
        setActiveFilter: handleActiveFilter,
        setAction: handleFilterObject,
        errMsg: errMsg,
        setErrMsg: setErrMsg,
        type: "multiDateSelect"
      },
      {
        label: "Tags",
        keyValue: "tags",
        placeholder: "Select",
        selected: getSelectedItems(data?.tags),
        data: data?.tags || [],
        setAction: handleFilterObject,
        activeFilter: activeDropdown,
        setActiveFilter: handleActiveFilter,
        type: "multiSelectApply",
        count: 2
      },
      {
        label: "Contact Lists",
        keyValue: "listIds",
        placeholder: "Select", // Select
        selected: getSelectedItems(data?.listIds),
        data: getListArr(),
        setAction: handleFilterObject,
        activeFilter: activeDropdown,
        setActiveFilter: handleActiveFilter,
        type: "multiSelectApply",
        count: 2
      },
      {
        label: "Channel",
        keyValue: "channel",
        placeholder: "Select", // Select
        selected: getSelectedItems(data?.channel),
        data: data?.channel || [],
        setAction: handleFilterObject,
        activeFilter: activeDropdown,
        setActiveFilter: handleActiveFilter,
        type: "multiSelectApply",
        count: 2
      }
    ];

    const actionList = [
      {
        className: "cancelFilter",
        name: "Cancel",
        id: "outboxCancelFilter",
        type: "cancel"
      },
      {
        className: `applyFilter ${
          outboxStoreData?.filterList?.length > 0 ||
          outboxStoreData?.tempFilterList?.length > 0
            ? ""
            : "disabled"
        }`,
        name: "Apply Filter",
        id: "outboxApplyFilter",
        type: "apply"
      }
    ];

    useEffect(() => {
      return () => {
        setActiveDropdown("");
        setErrMsg("");
      };
    }, []);

    return (
      <div className="outboxFilterByOption">
        <FilterHeader
          isFilterApplied={
            outboxStoreData?.filterList?.length > 0 ||
            outboxStoreData?.tempFilterList?.length > 0
          }
          submitCbk={submitAction}
        />
        <div className="filterBody">
          {dropdownList?.map(item => {
            const { keyValue = "", type = "" } = item || {};
            if (type === "singleSelect") {
              return <ConnectInboxDropdown key={keyValue} {...item} />;
            } else if (type === "multiInputSelect") {
              return <AddMultiInputEmail key={keyValue} {...item} />;
            } else if (type === "multiSelectApply") {
              return <MutliSelectDropdown key={keyValue} {...item} />;
            } else if (type === "multiDateSelect") {
              return <MultiSelectDateFilter key={keyValue} {...item} />;
            }
          })}
        </div>
        <FilterAction submitCbk={submitAction} actionList={actionList} />
      </div>
    );
  }
);

const FilterHeader = ({ isFilterApplied = false, submitCbk = () => {} }) => {
  return (
    <div className="filterHeader">
      <span className="name">Filter</span>
      <span
        className={`clearBtn ${(isFilterApplied ? "" : "disabled") || ""}`}
        onClick={() => submitCbk("clear")}
      >
        Clear all
      </span>
    </div>
  );
};

const FilterAction = ({ actionList = [], submitCbk = () => {} }) => {
  const handleAction = (event = "") => {
    let actionType = Utils.performActionByClosestDom(event, "div", "data-type");
    actionType && submitCbk(actionType);
  };

  return (
    <div className="filterAction" onClick={e => handleAction(e)}>
      {(actionList?.length > 0 &&
        actionList.map((item, index) => {
          const { className = "", name = "", id = "", type = "" } = item || {};
          return (
            <div
              className={className}
              key={`${id}Action${index}`}
              data-type={type}
            >
              {name}
            </div>
          );
        })) ||
        ""}
    </div>
  );
};

export default OutboxFilterByOption;
