/* eslint-disable no-param-reassign */

import axios from "axios";
import { SERVER_URL, AUTH_URL, TOKEN_FOR_IP_INFO } from "Static/constant";

export const URLS = {
  loginValidation: `${SERVER_URL}/cm/member/isLoggedIn`,
  logout: `${AUTH_URL}/ms/member/logout`,

  // Templates
  createTemplate: `${SERVER_URL}/cm/template/add`,
  getAllTemplatesFolder: `${SERVER_URL}/cm/template/folder/all`,
  getAllTemplates: `${SERVER_URL}/cm/template/get`,
  deleteTemplate: `${SERVER_URL}/cm/template/delete/v2/`,
  createNewTemplateFolder: `${SERVER_URL}/cm/template/folder/v2/create`,
  updateTemplate: `${SERVER_URL}/cm/template/update`,
  moveTemplateToAnotherFolder: `${SERVER_URL}/cm/template/move/<<templateId>>/to/<<folderId>>`,

  // Sequences
  getAllSequenceData: `${SERVER_URL}/cm/seq/all`,
  getMySequenceData: `${SERVER_URL}/cm/seq/all`,
  getSelectedSeqData: `${SERVER_URL}/cm/seq/<<seqID>>`,
  getSeqStatistics: `${SERVER_URL}/cm/stat/`,
  getSeqStatisticsContacts: `${SERVER_URL}/cm/stat/<<seqId>>/contacts`,
  createNewSeqFolder: `${SERVER_URL}/cm/seq/create/folder`,
  getSequenceByFolder: `${SERVER_URL}/cm/seq/folder/`,
  getAllSequenceFolders: `${SERVER_URL}/cm/seq/getFolders`,
  createSequence: `${SERVER_URL}/cm/seq`,
  updateSequence: `${SERVER_URL}/cm/seq/`,
  checkSeqStatus: `${SERVER_URL}/cm/seq/status/`,
  deleteSeq: `${SERVER_URL}/cm/seq/`,
  pauseSeq: `${SERVER_URL}/cm/seq/pause/`,
  resumeSeq: `${SERVER_URL}/cm/seq/resume/`,
  validateBulkFiles: `${SERVER_URL}/cm/seq/bulk/validateV2`,
  scheduleBulk: `${SERVER_URL}/cm/seq/schedule_bulk`,
  stopSeq: `${SERVER_URL}/cm/seq/stop/`,
  saveScheduleTimeWindow: `${SERVER_URL}/cm/settings/timewindow`,
  getSettingsData: `${SERVER_URL}/cm/member-attribute`,
  updateBcc: `${SERVER_URL}/cm/bcc`,
  pauseContactSeq: `${SERVER_URL}/cm/seq/scheduledSeq/<<seqId>>/pause`,
  resumeContactSeq: `${SERVER_URL}/cm/seq/scheduledSeq/<<seqId>>/resume`,
  resumeOOOContactSeq: `${SERVER_URL}/cm/seq/resumeOOTO/<<seqId>>`,
  validateExcludeContact: `${SERVER_URL}/cm/seq/validate/exclude/<<sequenceId>>/<<email>>`,
  moveSequenceToAnotherFolder: `${SERVER_URL}/cm/seq/move/<<sequenceId>>/to/<<folderId>>`,
  getStepLevelMetrics: `${SERVER_URL}/cm/stat/step/metrics/<<sequenceId>>`,
  getEmailContentViaScheduledId: `${SERVER_URL}/cm/seq/email/content/`,
  getReplyEmailContent: `${SERVER_URL}/cm/replyEmail/get`,
  removeContactFromSequence: `${SERVER_URL}/cm/seq/contact/remove`,

  // Tasks
  createTask: `${SERVER_URL}/cm/task/custom`,
  deleteTask: `${SERVER_URL}/cm/task/`,
  getTaskFilter: `${SERVER_URL}/cm/task/filter`,
  getPerformTaskData: `${SERVER_URL}/cm/task/getTask/<<taskId>>`,
  getTaskIds: `${SERVER_URL}/cm/task/getTaskIds`,
  getPerformTaskFilter: `${SERVER_URL}/cm/task/getPerformTaskDetails`,
  getTaskAnalytics: `${SERVER_URL}/cm/task/analytics`,
  getTemplatesWithFolder: `${SERVER_URL}/cm/template/folderTemplates`,
  markAsComplete: `${SERVER_URL}/cm/task/status/complete`,
  snoozeTask: `${SERVER_URL}/cm/task/snooze`,
  bulkTasksOperation: `${SERVER_URL}/cm/bulk/task/perform`,
  tasksOperationLogs: `${SERVER_URL}/cm/bulk/logs`,

  initializeTeam: `${SERVER_URL}/cm/team/`,
  inviteUser: `${SERVER_URL}/cm/team/inviteMember`,
  getTeam: `${SERVER_URL}/cm/team/getTeam`,
  deactiveTeamUser: `${SERVER_URL}/cm/team/removeMember`,
  activeTeamUser: `${SERVER_URL}/cm/team/reActivateUser`,
  checkTeamStatus: `${SERVER_URL}/cm/team/getActiveUserTeamDetails`,
  editTeamMember: `${SERVER_URL}/cm/team/editMember`,
  resendTeamInvite: `${SERVER_URL}/cm/team/sendInvite`,
  validateInviteToken: `${SERVER_URL}/cm/team/validateInvite`,
  cancelTeamInvite: `${SERVER_URL}/cm/team/cancelInvite`,
  connectGauth: `${SERVER_URL}/cm/connect/gAuth`,
  getSharedSequence: `${SERVER_URL}/cm/seq/shared?source=WEB`,
  acceptTeamInvite: `${SERVER_URL}/cm/team/acceptInvite`,
  changeTeamStatus: `${SERVER_URL}/cm/team/changeStatus`,
  validateCaptcha: `${SERVER_URL}/cm/member/validateToken`,
  getTeamActiveList: `${SERVER_URL}/cm/team/members`,
  getTeamReport: `${SERVER_URL}/cm/reports/team`,
  getFeatureFlagWithTeam: `${SERVER_URL}/cm/subscription/activeSubscription`,
  getActiveSubscriptionPlans: `${SERVER_URL}/cm/subscription/getActiveSubscriptionPlans`,
  payment: `${SERVER_URL}/cm/subscription/subscribe`,
  isTeamSubscriptionActive: `${SERVER_URL}/cm/subscription/isTeamSubscriptionActive`,
  getUserProfile: `${SERVER_URL}/ms/member`,
  updateUserProfile: `${SERVER_URL}/cm/member/`,
  updateUserPassword: `${SERVER_URL}/cm/member/updatePassword`,
  emailPreferences: `${SERVER_URL}/cm/member/emailPreference`,
  getonBoardingData: `${SERVER_URL}/cm/member/onboardingData`,
  markOnboardingCompleted: `${SERVER_URL}/cm/member/onboardingComplete`,
  getInvitedDetails: `${SERVER_URL}/cm/team/getInvitedDetails`,
  rejectTeamInvite: `${SERVER_URL}/cm/team/rejectInvite`,
  inviteRejectedMember: `${SERVER_URL}/cm/team/inviteRejectedMember`,
  onboardingStarted: `${SERVER_URL}/cm/member/onboardingStarted`,
  exportLimitedContact: `${SERVER_URL}/cm/export/contact`,
  skipSequenceStage: `${SERVER_URL}/cm/task/skipStage`,
  getDefaultSeqTemplates: `${SERVER_URL}/cm/seq/defaultSequences`,
  saveAndUpdateSfSettingPreference: `${SERVER_URL}/cm/crm/sf/sync/preferences`,
  getUnsubscribe: `${SERVER_URL}/cm/unsubscribe`,
  bulkExpContToSF: `${SERVER_URL}/cm/crm/sf/export`,
  checkCustomerIdAvailability: `${SERVER_URL}/cm/member/checkCustomerIdAvailability`,
  purchaseDialerCredits: `${SERVER_URL}/cm/subscription/purchaseDialerCredits`,
  buyCreditWithoutSubscription: `${SERVER_URL}/cm/subscription/createCustomerAndPurchaseCredits`,

  updateSequenceFolderName: `${SERVER_URL}/cm/seq/folder`,
  updateTemplateFolderName: `${SERVER_URL}/cm/template/folder/v2/update`,
  getSharedTemplates: `${SERVER_URL}/cm/template/shared`,
  getBrandUrlCname: `${SERVER_URL}/cm/settings/brandedUrl/cName`,
  verifyBrandUrl: `${SERVER_URL}/cm/settings/brandedUrl/verify`,
  getBrandUrlData: `${SERVER_URL}/cm/settings/brandedUrl`,
  deleteBrandUrl: `${SERVER_URL}/cm/settings/brandedUrl/delete`,
  fileAttachmentUpload: `${SERVER_URL}/cm/upload/file`,
  deleteTemplateFolder: `${SERVER_URL}/cm/template/delete/`,
  uploadImage: `${SERVER_URL}/cm/upload/image`,
  sendTestEmail: `${SERVER_URL}/cm/testmail/send/mail`,
  deleteSequenceFolder: `${SERVER_URL}/cm/seq/delete/<<folderId>>`,
  sortBySequenceName: `${SERVER_URL}/cm/seq/sort`,
  sortByTemplateName: `${SERVER_URL}/cm/template/sort`,
  getContactTags: `${SERVER_URL}/cm/contact/tags/`,
  customFields: `${SERVER_URL}/cm/customFields/`,
  addSingleContactToSequence: `${SERVER_URL}/cm/seq/addContact`,

  stepsAnalytics: `${SERVER_URL}/cm/stat/stage/analytics/`,
  saveEmailSettings: `${SERVER_URL}/cm/settings/saveEmailSettings/`,
  dealifyCheckCouponCode: `${SERVER_URL}/cm/member/checkOfferCode?code=<<couponCode>>`,
  reportEmailInsights: `${SERVER_URL}/cm/reports/emailInsights`,
  reportSequenceInsights: `${SERVER_URL}/cm/reports/sequenceInsights`,
  bulkContactsTask: `${SERVER_URL}/cm/task/custom/bulk`,
  createFreeTrial: `${SERVER_URL}/cm/subscription/startFreeTrial`,
  checkIsBusinessEmail: `${SERVER_URL}/ms/isBusinessEmail`,
  removeSfAccount: `${SERVER_URL}/cm/crm/sf/authenticate`,
  saveSequenceSetting: `${SERVER_URL}/cm/seq/settings`,
  csvImportsList: `${SERVER_URL}/cm/settings/bulk/sequences/imports`,

  //Accounts
  getAccountStages: `${SERVER_URL}/cm/accounts/stages`,
  getAccountList: `${SERVER_URL}/cm/accounts/get`,
  getAccountAnalytics: `${SERVER_URL}/cm/accounts/analytics`,
  getAccountTimeline: `${SERVER_URL}/cm/actlog/account/<<accountId>>`,
  getAccountTasksAnalytics: `${SERVER_URL}/cm/task/analytics/v2`,

  //reportsV2
  getUserReports: `${SERVER_URL}/cm/reports/userInsights`,
  getGroupReports: `${SERVER_URL}/cm/reports/groupInsights`,
  getSequenceReports: `${SERVER_URL}/cm/reports/v2/sequenceInsights`,
  getTableHeaders: `${SERVER_URL}/cm/table/config/get?tableName=`,
  editTableHeaders: `${SERVER_URL}/cm/table/config/edit`,
  getSequenceAnalytics: `${SERVER_URL}/cm/reports/sequenceAnalytics`,

  // Contacts
  getContactFromSalesforceId: `${SERVER_URL}/cm/contacts/salesforceId/<<salesforceId>>`,
  addSingleContact: `${SERVER_URL}/cm/contacts/single`,
  getContactAllTask: `${SERVER_URL}/cm/contacts/<<contactId>>/task/all/stats`,
  getContactFilter: `${SERVER_URL}/cm/contacts/analytics`,
  getContactUploadStatus: `${SERVER_URL}/cm/seq/bulkJobStatus`,
  getAllContacts: `${SERVER_URL}/cm/contacts/filter`,
  validateToContactStatus: `${SERVER_URL}/cm/contacts/status/<<email>>`,
  addContactsToDirectory: `${SERVER_URL}/cm/contacts/`,
  updateContactsToDirectory: `${SERVER_URL}/cm/contacts/update`,
  updateStageInDirectory: `${SERVER_URL}/cm/contacts/stage/change`,
  optOutContact: `${SERVER_URL}/cm/contacts/optout`,
  addSingleContact: `${SERVER_URL}/cm/contacts/single`,
  unsubscribeContact: `${SERVER_URL}/cm/contacts/unsubscribe`,
  sequenceMarkAsComplete: `${SERVER_URL}/cm/contacts/markAsComplete`,
  validateDailyLimitForContacts: `${SERVER_URL}/cm/contacts/validate/dailyLimit`,
  validateDailyLimitForSequenceContacts: `${SERVER_URL}/cm/stat/validate/contacts`,
  validateBulkContact: `${SERVER_URL}/cm/contacts/validate/bulk/mandatoryFields`,
  updateContactOwner: `${SERVER_URL}/cm/contacts/updateOwner`,
  exportContactsToHubspot: `${SERVER_URL}/cm/bulk/contact/crm/export`,

  // Outbox
  outbox: `${SERVER_URL}/cm/outbox/emails`,
  outboxFilter: `${SERVER_URL}/cm/outbox/analytics`,
  outboxPopupContent: `${SERVER_URL}/cm/outbox/email/content`,
  outboxSendEditEmail: `${SERVER_URL}/cm/outbox/email/edit`,
  exportOutboxEmails: `${SERVER_URL}/cm/export/outbox`,
  markOutboxMailAsRead: `${SERVER_URL}/cm/outbox/email/markAsRead`,
  unreadCount: `${SERVER_URL}/cm/outbox/unreadCount`,

  // Slack
  connectSlack: `${SERVER_URL}/cm/crm/slack/authenticate`,
  getSlackAccountDetails: `${SERVER_URL}/cm/crm/slack/connectedDetails`,
  removeSlackAccount: `${SERVER_URL}/cm/crm/slack/authenticate`,
  getSlackChannels: `${SERVER_URL}/cm/crm/slack/channels?cursor=`,
  updateMemberConfig: `${SERVER_URL}/cm/crm/slack/updateMemberConfig`,

  // Zoho
  connectZoho: `${SERVER_URL}/cm/crm/zoho/authenticate`,
  removeZohoAccount: `${SERVER_URL}/cm/crm/zoho/authenticate`,
  getZohoAccountDetails: `${SERVER_URL}/cm/crm/zoho/getConfig`,
  getLeadsView: `${SERVER_URL}/cm/crm/zoho/views/list/Leads`,
  getContactsView: `${SERVER_URL}/cm/crm/zoho/views/list/Contacts`,
  syncNow: `${SERVER_URL}/cm/crm/zoho/syncNow`,
  getAllZohoOwners: `${SERVER_URL}/cm/crm/zoho/users/list/ActiveUsers`,
  updateZohoConfig: `${SERVER_URL}/cm/crm/zoho/updateConfig`,
  getAllZohoFields: `${SERVER_URL}/cm/crm/zoho/getAdditionalFields`,
  getZohoSyncLogs: `${SERVER_URL}/cm/crm/zoho/logs/<<pageNo>>/<<limit>>`,

  // Hubspot
  connectOrDisconnectHubspot: `${SERVER_URL}/cm/crm/hubspot/authenticate`,
  getHubspotAccountDetails: `${SERVER_URL}/cm/crm/hubspot/getConfig`,
  getAllHubspotFields: `${SERVER_URL}/cm/crm/hubspot/getAdditionalFields`,
  getHubspotContactsList: `${SERVER_URL}/cm/crm/hubspot/contact/list`,
  getAllHubspotOwners: `${SERVER_URL}/cm/crm/hubspot/users/list`,
  updateHubspotConfig: `${SERVER_URL}/cm/crm/hubspot/updateConfig`,
  syncHubspotNow: `${SERVER_URL}/cm/crm/hubspot/syncNow`,
  checkCrmConnectionForExport: `${SERVER_URL}/cm/crm/validateContactExport`,

  // Pipedrive
  connectOrDisconnectPipedrive: `${SERVER_URL}/cm/crm/pipedrive/authenticate`,
  getPipedriveAccountDetails: `${SERVER_URL}/cm/crm/pipedrive/getConfig`,
  getAllPipedriveFields: `${SERVER_URL}/cm/crm/pipedrive/getAdditionalFields`,
  getPipedriveContactsList: `${SERVER_URL}/cm/crm/pipedrive/contact/list`,
  getAllPipedriveOwners: `${SERVER_URL}/cm/crm/pipedrive/users/list`,
  updatePipedriveConfig: `${SERVER_URL}/cm/crm/pipedrive/updateConfig`,
  syncPipedriveNow: `${SERVER_URL}/cm/crm/pipedrive/syncNow`,

  // Salesforce
  connectSalesForce: `${SERVER_URL}/cm/crm/sf/authenticate`,
  getSalesforceAccountDetails: `${SERVER_URL}/cm/crm/salesforce/getConfig`,
  getAllSfFields: `${SERVER_URL}/cm/crm/salesforce/getAdditionalFields`,
  getAllSfOwners: `${SERVER_URL}/cm/crm/salesforce/users/list`,
  updateSfConfig: `${SERVER_URL}/cm/crm/salesforce/updateConfig`,
  syncSfNow: `${SERVER_URL}/cm/crm/salesforce/syncNow`,

  getSyncLogs: `${SERVER_URL}/cm/crm/sync/logs`,

  // Sender token
  getSenderToken: `${SERVER_URL}/cm/seq/validate/sender/tokens/`,
  completeScheduledSequenceContact: `${SERVER_URL}/cm/seq/scheduledSeq/<<seqId>>/complete`,
  checkIsEligibleForSkipStage: `${SERVER_URL}/cm/seq/isContactEligibleToSkipStage/<<contactId>>/<<seqId>>`,
  skipSequenceStageContact: `${SERVER_URL}/cm/seq/skipStage/<<contactId>>/<<seqId>>`,
  getIpInfo: `https://ipinfo.io/?token=${TOKEN_FOR_IP_INFO}`,

  // Triggers
  listAllTriggers: `${SERVER_URL}/cm/triggers/list`,
  createTrigger: `${SERVER_URL}/cm/triggers/`,
  updateTrigger: `${SERVER_URL}/cm/triggers/update`,
  deleteTrigger: `${SERVER_URL}/cm/triggers/delete/<<triggerId>>`,
  listAllTriggerSequences: `${SERVER_URL}/cm/seq/member/filter`,
  retryFailedContact: `${SERVER_URL}/cm/seq/retry/<<seqId>>`,

  // Multiple inbox
  connectOutlook: `${SERVER_URL}/cm/connect/outlookAuth`,
  revokeOutlook: `${SERVER_URL}/cm/settings/outlook`,
  revokeGmail: `${SERVER_URL}/cm/settings/gAuth`,
  getConnectedInboxes: `${SERVER_URL}/cm/settings/connectedInboxes`,
  updateConnectedInbox: `${SERVER_URL}/cm/settings/updateInboxSettings`,
  revokeSmtp: `${SERVER_URL}/cm/settings/smtp`,
  testSmtpImapConnection: `${SERVER_URL}/cm/settings/test/smtp`,
  saveSmtpImapConnectedInbox: `${SERVER_URL}/cm/settings/connect/smtp`,
  removeInboxFromSeq: `${SERVER_URL}/cm/seq/removeInbox`,
  addInboxToSeq: `${SERVER_URL}/cm/seq/addInbox`,
  getWarmingData: `${SERVER_URL}/cm/settings/inbox/getWarmupMetricsByDate`,
  saveWarmupSettings: `${SERVER_URL}/ew/updateInboxSettings`,
  getWebsiteDomain: `${SERVER_URL}/ss/search/domain/getDomainInfo`,

  // Writing Assistant"
  getAlternativeEmailBody: `${SERVER_URL}/cm/ai/rewriteEmailBody`,
  createAISequence: `${SERVER_URL}/cm/ai/sequence`,
  generateSubject: `${SERVER_URL}/cm/ai/generateSubject`,
  generateScore: `${SERVER_URL}/cm/ai/generateScore`,
  getRewriteAiPrompts: `${SERVER_URL}/cm/ai/getPrompts`,
  generateContent: `${SERVER_URL}/cm/ai/generateContent`,

  // Register
  validateBusinessEmail: `${AUTH_URL}/ms/validate/businessEmail`,
  signupUser: `${AUTH_URL}/ms/member/signup`,
  loginUser: `${AUTH_URL}/ms/member/login`,
  forgotPasswordUser: `${SERVER_URL}/ms/member/generateForgotPasswordEmail`,
  activateUser: `${SERVER_URL}/ms/member/activate`,
  resetPasswordUser: `${SERVER_URL}/ms/member/resetPassword`,

  // Dialer
  getDailerToken: `${SERVER_URL}/ds/twilio/dialer_token`,
  getMyPhoneNumbers: `${SERVER_URL}/ds/twilio/my_phone_numbers`,
  updateDefaultPhoneNumber: `${SERVER_URL}/ds/twilio/default/ph_number`,
  deletePhoneNumber: `${SERVER_URL}/ds/twilio/deletePhNumber`,
  getAvailablePhoneNumbers: `${SERVER_URL}/ds/twilio/available_numbers`,
  purchaseNumber: `${SERVER_URL}/ds/twilio/purchase_number`,
  checkAndCreateTwilioSubaccount: `${SERVER_URL}/ds/twilio/checkAndAddSubAccount`,
  verifyMyPhNumber: `${SERVER_URL}/ds/twilio/phone/verify`,
  isPhoneNumberVerified: `${SERVER_URL}/ds/twilio/phone/isVerified`,
  callRecordingSetting: `${SERVER_URL}/cm/settings/dialerSettings`,
  startRecording: `${SERVER_URL}/ds/twilio/phone/recording/start/<<callSid>>`,
  pasueRecording: `${SERVER_URL}/ds/twilio/phone/recording/pause/<<callSid>>`,
  resumeRecording: `${SERVER_URL}/ds/twilio/phone/recording/resume/<<callSid>>`,
  dialerLogCall: `${SERVER_URL}/cm/phone/outcome`,
  manualLogCall: `${SERVER_URL}/cm/phone/logCall`,
  exportCallLogs: `${SERVER_URL}/cm/export/calls`,

  // Dialer Settings Call Dispositions
  getCallStageList: `${SERVER_URL}/cm/calldp/`,
  createNewCallStage: `${SERVER_URL}/cm/calldp/`,
  updateCallStage: `${SERVER_URL}/cm/calldp/update`,
  updateCallStageVisiblity: `${SERVER_URL}/cm/calldp/updateVisibility`,
  deleteCallStage: `${SERVER_URL}/cm/calldp/delete`,

  // Calls
  getCallsOutcomeFilterValue: `${SERVER_URL}/cm/phone/counts`,
  getCallsOverviewData: `${SERVER_URL}/cm/phone/`,
  getAnalyticsData: `${SERVER_URL}/cm/phone/analytics`,
  getAnalyticsTeamData: `${SERVER_URL}/cm/phone/analytics/team`,
  bulkLogCalls: `${SERVER_URL}/cm/phone/logCall/bulk`,

  // Bulk operations
  performContactsBulkOperation: `${SERVER_URL}/cm/bulk/perform`,

  // Sequence Stages
  deleteSequenceStage: `${SERVER_URL}/cm/seq/removeStage/<<sequenceId>>/<<stepId>>`,
  deleteSequenceStageVariant: `${SERVER_URL}/cm/seq/removeVariant/<<sequenceId>>/<<stepId>>/<<variantId>>`,
  pauseAndResumeSequenceStageVariant: `${SERVER_URL}/cm/seq/pauseVariant/<<sequenceId>>/<<stepId>>/<<variantId>>`,

  // Contact Timeline:
  getContactStage: `${SERVER_URL}/cm/contactStage/?source=contacts`,
  getContactDetail: `${SERVER_URL}/cm/contacts/detail/<<contactId>>`,
  getContactTimelineActivity: `${SERVER_URL}/cm/actlog/contact/<<contactId>>`,
  getReplyAndForwardDetails: `${SERVER_URL}/cm/timeline/email/get`,
  validateEmailStatus: `${SERVER_URL}/cm/contacts/status`,
  sendTimelineEmail: `${SERVER_URL}/cm/timeline/email/send`,
  sendReplyEmail: `${SERVER_URL}/cm/replyEmail/send`,
  getLinkedInReply: `${SERVER_URL}/cm/contacts/liReply`,

  // Dashboard
  getLiveFeedData: `${SERVER_URL}/at/dashboard/feed`,
  getActivityInsights: `${SERVER_URL}/cm/reports/activityInsights`,
  getTeamData: `${SERVER_URL}/cm/team/filterDetails`,

  // Data Search page
  getContactList: `${SERVER_URL}/cm/data/list/get`,
  createContactList: `${SERVER_URL}/cm/data/list`,
  editContactList: `${SERVER_URL}/cm/data/list/update`,
  deleteContactList: `${SERVER_URL}/cm/data/list`,
  searchDataPage: `${SERVER_URL}/ss/search/contacts`,
  companyNameList: `${SERVER_URL}/ss/search/company/autocomplete`,
  locationNameList: `${SERVER_URL}/ss/search/location/autocomplete`,
  keywordNameList: `${SERVER_URL}/ss/search/keyword/autocomplete`,
  industriesList: `${SERVER_URL}/cm/data/industries`,
  purchaseContact: `${SERVER_URL}/cm/data/contact/purchase`,
  addContactToList: `${SERVER_URL}/cm/data/list/addContact`,
  exportContactsAsCSV: `${SERVER_URL}/cm/data/contact/export/csv`,
  dsAddToSequence: `${SERVER_URL}/cm/data/contact/addToSequence`,

  // Credit Usage
  getCreditsUsage: `${SERVER_URL}/cm/data/credits`,
  purchaseEmailValidationCredits: `${SERVER_URL}/cm/subscription/purchaseValidationCredits`,

  // Email verification
  signupVerification: `${SERVER_URL}/ms/member/resendVerificationEmail`,

  // Team Management
  getTeamGroups: `${SERVER_URL}/cm/team/group/get`,
  createTeam: `${SERVER_URL}/cm/team/group`,
  deleteGroup: `${SERVER_URL}/cm/team/group`,
  getGroupMembers: `${SERVER_URL}/cm/team/group/members/list`,
  addMemberToGroup: `${SERVER_URL}/cm/team/group/addMember`,
  removeMemberFromGroup: `${SERVER_URL}/cm/team/group/removeMember`,
  makeOwner: `${SERVER_URL}/cm/team/group/makeOwner`,
  editTeamName: `${SERVER_URL}/cm/team/group/edit`,

  // Calendar Settings
  getCalendarConnnectedAccount: `${SERVER_URL}/cs/calendar/get`,
  editCalendarBaseUrl: `${SERVER_URL}/cs/calendar/editUrl`,
  updateBrandToggle: `${SERVER_URL}/cs/calendar/branding`,
  createCalendarEvent: `${SERVER_URL}/cs/createEvent`,
  cloneCalendarEvent: `${SERVER_URL}/cs/cloneEvent`,
  getCalendarEventsList: `${SERVER_URL}/cs/events/list`,
  editCalendarEvent: `${SERVER_URL}/cs/event/edit`,
  deleteCalendarEvent: `${SERVER_URL}/cs/deleteEvent`,
  toggleCalendarEvent: `${SERVER_URL}/cs/toggleEventStatus`,
  getFreeSlots: `${SERVER_URL}/cs/freeSlots`,
  bookMeetingByContactCard: `${SERVER_URL}/cs/contact/bookMeeting`,
  listActiveCalendarEvent: `${SERVER_URL}/cs/contact/book/events`,
  connectCalendar: `${SERVER_URL}/cs/connect`,
  deleteCalender: `${SERVER_URL}/cs/connect/delete`,
  getCalendarInboxByType: `${SERVER_URL}/cm/settings/getInboxByType?type=`,

  // Contact stages
  getContactStages: `${SERVER_URL}/cm/contactStage/?source=settings`,
  deleteStage: `${SERVER_URL}/cm/contactStage/delete`,
  updateStageName: `${SERVER_URL}/cm/contactStage/updateName`,
  updateVisibility: `${SERVER_URL}/cm/contactStage/updateVisibility`,

  // Do not contact domains
  addDomain: `${SERVER_URL}/cm/dnc/`,
  removeDomain: `${SERVER_URL}/cm/dnc/remove/`,

  // StageRules
  saveRule: `${SERVER_URL}/cm/contactStage/rule`,
  getRules: `${SERVER_URL}/cm/contactStage/rules`,

  // TimeZone
  listTimeZone: `${SERVER_URL}/cm/settings/timezones`,

  // Discover saved search
  saveSearch: `${SERVER_URL}/ss/search/save`,
  getSavedSearch: `${SERVER_URL}/ss/search/savedSearch?pageNo=1`,
  deleteSearch: `${SERVER_URL}/ss/search/savedSearch/delete`,
  editSearch: `${SERVER_URL}/ss/search/savedSearch/rename`,

  //Operations
  addRemoveTags: `${SERVER_URL}/cm/contact/tags/update`,

  //website tracking
  getVisitorsData: `${SERVER_URL}/cm/webvisitor/getData`,
  getVisitorsAnalytics: `${SERVER_URL}/cm/webvisitor/getAnalytics`,
  getInstalledScriptDetails: `${SERVER_URL}/cm/webvisitor/getInstalledDetails`,

  // Domain health center
  getDomainHealth: `${SERVER_URL}/cm/settings/dhc`,
  getDkimLabel: `${SERVER_URL}/cm/settings/dhc/dkim`,
  getAllConnectedInboxes: `${SERVER_URL}/cm/settings/inboxes/all`,
  getDomainScore: `${SERVER_URL}/cm/settings/dhc/analytics`,

  //gloabalHeader
  getContactsBySearchKeywords: `${SERVER_URL}/cm/contacts/autocomplete?search=`,

  // Email warmer + connected Inbox
  searchConnectedInbox: `${SERVER_URL}/cm/settings/inbox/search?text=`,
  enableOrDisableWarming: `${SERVER_URL}/ew/enableWarming`,
  connectInboxInWarming: `${SERVER_URL}/ew/connect/googleOauth`,
  connectAppPwd: `${SERVER_URL}/ew/connect/googleSmtp`,
  getConnectedInboxesWarming: `${SERVER_URL}/cm/settings/warming/connectedInboxes`,
  getWarmingCommunityMsgData: `${SERVER_URL}/cm/member/warmingCommunityMessageData`,
  getWarmingCommunityMsgShown: `${SERVER_URL}/cm/member/warmingCommunityMessageShown`,
  getScopeDetails: `${SERVER_URL}/cm/connect/outlookAddScope`,

  // Activate sequence
  startSeq: `${SERVER_URL}/cm/seq/start`,

  //Accounts
  getAccountInsights: `${SERVER_URL}/cm/accounts/accountInsights`,
  getAccountEmailInsights: `${SERVER_URL}/cm/accounts/emailInsights`,
  updateAccountStage: `${SERVER_URL}/cm/accounts/stage/change`,
  performBulkAccountOperation: `${SERVER_URL}/cm/bulk/account/perform`,
  updateAccount: `${SERVER_URL}/cm/accounts/edit`,
  updateAccountOwner: `${SERVER_URL}/cm/accounts/owner/change`,

  //Account Notes
  getAccountNotes: `${SERVER_URL}/cm/notes/get`,
  delAccountNotes: `${SERVER_URL}/cm/notes/delete`,
  addAccountNotes: `${SERVER_URL}/cm/notes/add`,
  updateAccountNotes: `${SERVER_URL}/cm/notes/update`,
  accountNotesSearch: `${SERVER_URL}/cm/notes/search`,
  // Live Feed
  getEmailActivityData: `${SERVER_URL}/cm/actlog/activity/<<id>>`,

  //Email Prefrences
  getEmailpreferences: `${SERVER_URL}/cm/notification/settings/get`,
  editEmailpreferences: `${SERVER_URL}/cm/notification/settings`,

  //LinkedIn Automation
  connectLinkedInAccount: `${SERVER_URL}/cm/li/connect`,
  getConnectedLinkedInAccounts: `${SERVER_URL}/cm/li/filter`,
  deleteLinkedInAccounts: `${SERVER_URL}/cm/li/delete`,
  saveSettingsInLinkedIn: `${SERVER_URL}/cm/li/saveSettings`,
  //Send LinkedIn message
  sendLinkedInMessage: `${SERVER_URL}/cm/li/sendReplyMessage`
};

export const makeApi = (config, doCancelRequest, hasCredentials = true) => {
  const { CancelToken } = axios;
  config.method = config.method || "GET";
  config.headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  if (hasCredentials) {
    config.withCredentials = true;
  }

  if (doCancelRequest !== undefined) {
    config.cancelToken = new CancelToken(function executor(cancelHttpRequest) {
      doCancelRequest(cancelHttpRequest);
    });
  }

  return axios(config)
    .then(response => {
      return response;
    })
    .catch(response => {
      return response;
    });
};

export default makeApi;
